import { h } from 'preact'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useMyPublicProfileDid } from 'resources/auth'
import {
  useMyOrganizations,
  usePublicProfiles,
} from 'lib/membershipAppStateHooks'

import IconButton from 'components/IconButton'
import NotificationBadge from 'components/NotificationBadge'
import Header from 'components/Header'
import IconRow from 'components/IconRow'
import IconRowList from 'components/IconRowList'
import ChatChannelAvatar from 'components/ChatChannelAvatar'
import OrganizationIcon from 'components/OrganizationIcon'
import EndUserAvatarStack from 'components/EndUserAvatarStack'
import EndUserDisplayNameList from 'components/EndUserDisplayNameList'
import ChatChannelListMember from 'components/ChatChannelListMember'
import './index.sass'

const ChatNotificationsList = memo(({ synopsis = [], onMemberClick }) => {
  const myPublicProfileDid = useMyPublicProfileDid()
  const { myOrganizations } = useMyOrganizations('ChatNotificationsList')

  const publicProfileDids = new Set()
  synopsis.forEach(chatChannel =>
    (chatChannel.memberUserDids || []).forEach(did => publicProfileDids.add(did))
  )
  const { publicProfiles } = usePublicProfiles(publicProfileDids, 'ChatNotificationsList')
  let chats = []

  synopsis.forEach(chatChannel => {
    let name, avatar
    if (!chatChannel.latestAt) return
    let organization = myOrganizations.find(o =>
      o.apikey === chatChannel.organizationApikey
    )
    if (!organization) organization = {apikey: chatChannel.organizationApikey}
    if (chatChannel.type === 'general'){
      avatar = <ChatChannelAvatar size="sm" value="#"/>
      name = organization.name || chatChannel.organizationApikey
    }else{
      const otherPublicProfiles = chatChannel.memberUserDids
        .filter(did => did !== myPublicProfileDid)
        .map(did => publicProfiles.find(p => p.did === did))
      avatar = <EndUserAvatarStack size="sm" publicProfiles={otherPublicProfiles} />
      name = <EndUserDisplayNameList
        publicProfiles={otherPublicProfiles}
        noLinks
      />
    }
    const date = moment(chatChannel.latestAt || chatChannel.createdAt).toDate().getTime()
    const unread = chatChannel.unread || 0
    const { preview } = chatChannel

    chats.push({
      key: chatChannel.uid,
      href: `/${chatChannel.organizationApikey}/chat/${chatChannel.uid}`,
      icon: <OrganizationIcon {...{ organization }}/>,
      row: <ChatChannelListMember {...{
        date,
        preview,
        avatar,
        name,
      }}/>,
      unread,
      date,
      buttons: (unread > 0 && <NotificationBadge total={unread}/>)
    })
  })

  chats = chats.sort(sortChats)

  return <div className="ChatNotificationsList">
    {chats.length === 0
      ? <Header size="sm" centered italic>You have no chat messages.</Header>
      : <IconRowList>
        {chats.map(({ key, href, icon, row, buttons }) =>
          <IconRow {...{key, href, icon, row, buttons, onClick: onMemberClick}}/>
        )}
      </IconRowList>
    }
  </div>
})

ChatNotificationsList.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  children: IconButton.propTypes.children,
}

export default ChatNotificationsList

function sortChats(a, b){
  if (a.unread > 0 && b.unread === 0) return -1
  if (a.unread === 0 && b.unread > 0) return 1
  return b.date - a.date
}
