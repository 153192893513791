import { h } from 'preact'
import { useMemo, useRef, useErrorBoundary } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import { setPageTitle } from 'lib/Page'
import { refToDOMNode } from 'lib/preactHelpers'
import {
  useOrganization,
  useCanonicalOrganizationApikeyRedirector,
  useMyOrganizationMembership,
  useAcceptedMembershipsForOrganization,
  useOrganizationMembershipRequests,
} from 'lib/membershipAppStateHooks'
import { useOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'
import { reportOrganizationVisit } from 'lib/recentOrganizations'
import useScrollUpOnNaturalPageChange from 'lib/useScrollUpOnNaturalPageChange'

import { useSISA } from 'resources/SISAs'
import { setQuery } from 'resources/location'

import NotFoundPage from '../NotFoundPage'
import Loading from 'components/Loading'
import Unauthorized from 'components/Unauthorized'
import Link from 'components/Link'
import IconButton from 'components/IconButton'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import InterfaceHelp from 'components/InterfaceHelp'
import OrganizationIcon from 'components/OrganizationIcon'
import OrganizationBanner from 'components/OrganizationBanner'
import BannerNav from 'components/BannerNav'
import Subtext from 'components/Subtext'
import SingleLine from 'components/SingleLine'
import OrganizationSISAButton from 'components/OrganizationSISAButton'
import OrganizationJoinModal from 'components/OrganizationJoinModal'
import OrganizationMembershipButton from 'components/OrganizationMembershipButton'
import OrganizationAnnouncements from 'components/OrganizationAnnouncements'
import { useAPortalInterfaceWalkthroughInitiator } from 'components/APortalInterfaceWalkthrough'
import interfaceHelpCopy from 'components/APortalInterfaceWalkthrough/copy'

import { useOrganizationPagePages } from './pages'
import './index.sass'

export default function OrganizationPage(props){
  const { organizationApikey } = props.params
  const {
    organization,
    organizationLoadingError,
    organizationNotFound,
  } = useOrganization(organizationApikey, 'OrganizationPage')

  useCanonicalOrganizationApikeyRedirector(organizationApikey, 'OrganizationPage')

  if (organizationLoadingError)
    return <div className="OrganizationPage">
      <ErrorMessage error={organizationLoadingError} />
    </div>

  if (organizationNotFound)
    return <NotFoundPage {...props}/>

  if (!organization)
    return <div className="OrganizationPage"><Loading type="fullPage"/></div>

  return <ActualOrganizationPage {...{
    ...props,
    key: organizationApikey,
    organizationApikey,
    organization,
  }}/>
}

function ActualOrganizationPage(props){
  const ref = useRef()
  const {
    currentUser,
    location,
    organizationApikey,
    organization,
  } = props

  reportOrganizationVisit(organizationApikey)
  useAPortalInterfaceWalkthroughInitiator(organizationApikey, 'OrganizationPage')

  const {
    isMember, isAdmin, isCurator
  } = useMyOrganizationMembership(organizationApikey, 'OrganizationPage')

  const { pages, findPage } = useOrganizationPagePages({
    orgIsClosed: !!organization.is_closed,
    orgIsPrivate: !!organization.is_private,
    userIsLoggedIn: !!currentUser,
    userIsMember: isMember,
    userIsCurator: isCurator,
    userIsAdmin: isAdmin,
    forumMembersOnly: organization.forum_only_visible_to_members,
  })

  const [
    renderOrganizationAnnouncementsModal,
    renderOrganizationAnnouncementsButton,
  ] = OrganizationAnnouncements.use({
    organizationApikey, isAdmin, isCurator, isMember,
    modalOpen: !!location.query.ancmt,
    onModalClose(){ setQuery({ ancmt: null }) }
  })

  props = {
    ...props,
    pages,
    findPage,
    isMember,
    isAdmin,
    isCurator,
    renderOrganizationAnnouncementsButton,
  }

  return <div ref={ref} className="OrganizationPage" key={organization.apikey}>
    <MainNav {...{
      ...props,
      key: `MainNav-${organization.apikey}`,
    }}/>
    <PageContent {...{
      ...props,
      key: `PageContent-${organization.apikey}`,
    }}/>
    <OrganizationJoinModal {...{organization, location, currentUser, isMember}}/>
    {isMember && renderOrganizationAnnouncementsModal()}
  </div>
}

function MainNav(props){
  const {
    location,
    organization,
    isAdmin,
    isCurator,
    findPage,
    isMember,
  } = props
  const sisa = useSISA(organization.apikey)

  const {
    organizationMembershipRequests,
  } = useOrganizationMembershipRequests(organization.apikey, 'OrganizationPage')

  const hasPendingMembershipRequests = (
    (isAdmin || isCurator) &&
    organizationMembershipRequests.length > 0
  )

  const pathnamePrefix = `/${organization.apikey}`

  const navButtons = useMemo(
    () => {
      const navButtons = []
      const navButton = ({path, name, icon, help, className = '', bang}) => {
        const {page} = findPage(path)
        const root = path === '/'
        navButtons.push({
          disabled: !page || page.unauthorized,
          href: pathnamePrefix + (root ? '' : path),
          className: `OrganizationPage-navButton-${name} ${className}`,
          value: name + (bang ? '❗' : ''),
          icon,
          selectedIfExact: root,
          help: {
            inside: true,
            ...help,
            className: `OrganizationPage-navButton-${name}-help`,
          },
        })
      }
      navButton({
        path: '/',
        name: 'Home',
        icon: 'home',
        help: interfaceHelpCopy.hubAboutPage,
      })
      if (isDataYogi){
        navButton({
          path: '/marketplace',
          name: 'Marketplace',
          icon: 'market',
        })
        navButton({
          path: '/my-stuff',
          name: 'My Stuff',
          icon: 'brandposts',
        })
        // navButton({
        //   path: '/covid',
        //   name: 'Covid',
        //   icon: 'eboard',
        // })
        if (sisa.sisaId) navButton({
          path: '/my-data',
          name: 'My Data',
          icon: 'data-tr',
        })
      }else{
        navButton({
          path: '/published',
          name: 'Published',
          icon: 'public-feed',
          help: interfaceHelpCopy.hubPublishedPage,
        })
        navButton({
          path: '/forum',
          name: 'Forum',
          icon: 'channel',
          help: interfaceHelpCopy.hubForumPage,
        })
        navButton({
          path: '/chat',
          name: 'Chat',
          icon: 'messages',
          help: interfaceHelpCopy.chat,
        })
        navButton({
          path: '/members',
          name: 'Members',
          icon: 'people',
          help: interfaceHelpCopy.hubPeoplePage,
          bang: !!hasPendingMembershipRequests,
        })
        if (organization.is_network)
          navButton({
            path: '/network',
            name: 'Network',
            icon: 'network',
            help: interfaceHelpCopy.hubNetworkPage,
          })
      }
      return navButtons
    },
    [isDataYogi, organization, findPage, hasPendingMembershipRequests, sisa]
  )

  const extraButtons = [
    isDataYogi
      ? <OrganizationSISAButton {...{organizationApikey: organization.apikey, sisa}} />
      : <OrganizationMembershipButton {...{ organization }}/>
  ]
  if (isAdmin || isCurator) {
    let href = `${pathnamePrefix}/admin`
    if (location.pathname.startsWith(href)) href = `${location}`
    extraButtons.push(
      <InterfaceHelp {...interfaceHelpCopy.hubAdminCog}>
        <IconButton type="cog" size="sm" bordered padded href={href}/>
      </InterfaceHelp>
    )
  }

  const banner = <OrganizationBanner {...{organization}}/>

  const icon = <Link
    className="OrganizationPage-MainNav-icon"
    href={`/${organization.apikey}`}
  >
    <OrganizationIcon organization={organization} size="md" bordered />
  </Link>

  const name = <NameAndStats {...{organization, isMember}}/>

  return <BannerNav {...{
    banner, icon, name, navButtons, extraButtons
  }}/>
}

function NameAndStats({ organization, isMember }){
  const organizationApikey = organization.apikey
  const {
    acceptedOrganizationMemberships = []
  } = useAcceptedMembershipsForOrganization(
    organizationApikey,
    'organizationPage'
  )
  const {
    organizationFeedSubscriptions = [],
  } = useOrganizationFeedSubscriptions(
    organizationApikey,
    'OrganizationPage',
  )

  const followerCount = organizationFeedSubscriptions.filter(s => s.organizationApikey === organizationApikey).length

  const membersLink = isDataYogi
    ? `/${organizationApikey}`
    : `/${organizationApikey}/members`

  return <div className="OrganizationPage-NameAndStats">
    <InterfaceHelp {...interfaceHelpCopy.hubAboutPage} className="OrganizationPage-NameAndStats-name">
      <Link href={`/${organization.apikey}`}>
        <Header size="xl">
          <SingleLine>
            <SingleLine.Shrink>{organization.name}</SingleLine.Shrink>
          </SingleLine>
        </Header>
      </Link>
    </InterfaceHelp>
    {
      !isDataYogi && (!organization.is_closed || isMember) &&
        <Subtext>
          <InterfaceHelp {...{
            title: `${PNFO.singular} Members`,
            content: (
              `Members are people who have been invited to join the ${PNFO.singular}. They can ` +
              `elevate forum posts, comment on them, post to the ${PNFO.singular}'s forum, ` +
              `and view private posts in the ${PNFO.singular}.`
            ),
          }}>
            <Link type="text" href={membersLink}>
              {acceptedOrganizationMemberships.length}&nbsp;
              Member{acceptedOrganizationMemberships.length > 1 ? 's' : ''}
            </Link>
          </InterfaceHelp>
          &nbsp;&nbsp;&nbsp;
          <InterfaceHelp {...{
            title: `${PNFO.singular} Followers`,
            content: (
              `Followers are people who have subscribed to the ${PNFO.singular} they receive ` +
              `the ${PNFO.singular}'s forum posts and published content on their main feed`
            ),
          }}>
            <Link type="text" href={membersLink}>
              {followerCount}&nbsp;
              Follower{followerCount > 1 ? 's' : ''}
            </Link>
          </InterfaceHelp>
        </Subtext>
    }
  </div>
}

function PageContent(props){
  const { organization, findPage } = props
  const path = `/${props.params.path || ''}`

  const [error, onDismiss] = useErrorBoundary(error =>
    console.error('OrganizationPage render error', error)
  )

  const ref = useRef()
  props.ref = ref
  const {page, params} = findPage(path)
  useScrollUpOnNaturalPageChange([page], () => {
    if (!page || page.title === 'Home') return 0
    let node = refToDOMNode(ref)
    if (node) node = node.parentNode.querySelector('.Navbar.BannerNav-DesktopNav')
    return node ? node.offsetTop : 0
  })

  if (!page) return <NotFoundPage {...props}/>

  const Component = page.comp
  props.params = {...props.params, ...params}

  setPageTitle(
    (page.title ? `${page.title} | ` : '') +
    `${organization.name || organization.apikey}`
  )

  if (
    page.unauthorized === 'notAdmin' ||
    page.unauthorized === 'notCurator'
  ) return <Unauthorized {...{ref}} />

  if (
    page.unauthorized === 'notLoggedIn'
  ) return <Unauthorized {...{
    ref,
    header: `${APP_NAME} Only Area!`,
    subtext: (
      `You must be logged in to ${APP_NAME} to visit this page.`
    ),
  }}/>

  if (
    page.unauthorized === 'notMember'
  ) return <Unauthorized {...{
    ref,
    header: 'Members Only Area!',
    subtext: (
      `You must be a member of ${organization.name}` +
      ` to visit this page.`
    ),
    destinationOrganizationApikey: organization.apikey,
    recommendation: props.loggedIn
      ? <OrganizationMembershipButton {...{
        ref,
        organization,
        hideIfMember: false,
      }}/>
      : undefined
  }}/>

  return error
    ? <ErrorMessage key="error" {...{ref, error, onDismiss}}/>
    : <Component {...props} />
}
