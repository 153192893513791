import { isLoggedIn } from '../resources/auth'
import { replaceLocation } from 'resources/location'
import api from '../api'
import { loadEntity } from 'lib/actionHelpers'
import { getBrowserNotificationSettings, createBrowserNotification } from 'lib/browserNotifications'

export function loadSynopses() {
  if (isLoggedIn()) return loadEntity.call(this, {
    reload: true,
    entityKey: 'chatSynopsis',
    request: async () => {
      const newChatSynopsis = await api.getChatSynopsis()
      createBrowserNotificationsForNewChatMessages(
        newChatSynopsis,
        this.getState().chatSynopsis,
      )
      return newChatSynopsis
    },
  })
  this.setState({ chatSynopsis: undefined })
}

function createBrowserNotificationsForNewChatMessages(newChatSynopsis, oldChatSynopsis) {
  if (
    window.document.hasFocus() ||
    !oldChatSynopsis ||
    !getBrowserNotificationSettings().recieveChatMessageBrowser
  ) return
  for (const group of newChatSynopsis) {
    if (!group.unread) continue
    createBrowserNotification({
      uuid: `newChatMessages-${group.uid}-${group.latestAt}`,
      title: `new chat messages`,
      body: `new chat messages in ${group.organizationApikey} ${group.type}`,
      destinationPath: `/${group.organizationApikey}/chat/${group.uid}`,
    })
  }
}

export function loadChatChannel(chatChannelUid) {
  return loadEntity.call(this, {
    reload: true,
    entityKey: `chat:channel:${chatChannelUid}`,
    request: () => api.getChatChannel(chatChannelUid)
  })
}

const didsToString = dids => dids.sort().join(',')

export async function createChatChannel({
  organizationApikey, type, memberUserDids, name
}) {
  if (type !== 'dm') throw new Error(`chatChannel.type can only be "dm"`)
  if (!Array.isArray(memberUserDids) || memberUserDids.length === 0)
    throw new TypeError(`memberUserDids must be a non-blank array of public profile dids`)
  const { chatSynopsis = [] } = this.getState()
  const uuid = didsToString(memberUserDids)
  let chatChannel = chatSynopsis
    .find(c => c.memberUserDids && didsToString(c.memberUserDids) === uuid)
  if (!chatChannel) chatChannel =
    await api.createChatChannel({ organizationApikey, type, memberUserDids, name })
  await replaceLocation(`/${organizationApikey}/chat/${chatChannel.uid}`)
  await this.takeAction('chat.loadSynopses')
}

export function loadMessages(chatChannelUid) {
  return loadEntity.call(this, {
    reload: true,
    entityKey: `chat:channel:${chatChannelUid}:messages`,
    request: () => api.getChatMessages(chatChannelUid),
  })
}

let fasterChatPolling = false
let pollingForSynopsisTimeout
export function startPollingForSynopsis() {
  if (pollingForSynopsisTimeout) return
  const frequency = fasterChatPolling ? 1000 : 5000
  const reloadSynopses = async () => {
    if (localStorage.stopChat) return
    await loadSynopses.call(this)
    pollingForSynopsisTimeout = setTimeout(reloadSynopses, frequency)
  }
  setTimeout(reloadSynopses, 100)
}

export function setFasterChatPolling(value) { fasterChatPolling = !!value }

export function stopPollingForSynopsis() {
  if (pollingForSynopsisTimeout) {
    clearTimeout(pollingForSynopsisTimeout)
    pollingForSynopsisTimeout = undefined
  }
}

export async function createMessage(chatChannelUid, { message, file, urlPreview }) {
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  const pendingChatMessagesKey = `${chatMessagesKey}:pending`
  const pendingChatMessage = {
    createdAt: Date.now(),
    uid: `pending-${Date.now()}`,
    channelUid: chatChannelUid,
    message,
    urlPreview,
  }

  if (file) pendingChatMessage.uploadId = this.takeAction('assets.upload', file)

  this.addToSet(pendingChatMessagesKey, [pendingChatMessage])

  if (file) {
    let upload = this.getState()['assets:uploads'][pendingChatMessage.uploadId]
    upload = await upload.promise
    if (!upload) {
      this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
      return
    }
    const { type, url } = upload

    const preview = upload.preview || {}
    delete preview.url
    message = `jlinc:dm:attachment:${JSON.stringify({ ...preview, url, type })}`
    console.log('NEW CHAT MESSAGe', message)
  }

  try {
    const chatMessage = await api.createChatMessage(chatChannelUid, message, urlPreview?.id)
    const chatMessages = Array.from(this.getState()[chatMessagesKey] || [])
    this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
    // TODO mamnnually update the digest latest, latestUid
    this.setState({
      [chatMessagesKey]: [...chatMessages, {...chatMessage, urlPreview}],
    })
  } catch (error) {
    this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
    this.addToSet(pendingChatMessagesKey, [{ ...pendingChatMessage, error }])
  }
}

export function retry(pendingChatMessage) {
  const { channel, message } = pendingChatMessage
  this.removeFromSet(`chatMessages:${channel}:pending`, [pendingChatMessage])
  this.takeAction('chatMessages.create', channel, message)
}

export async function markAsRead(chatChannelUid) {
  const chatMessages = this.getState()[`chat:channel:${chatChannelUid}:messages`] || []
  const latestChatMessage = chatMessages.filter(cm => !cm.readAt).reverse()[0]
  if (!latestChatMessage) return
  try {
    await api.markChatMessagesAsRead(latestChatMessage.uid)
  } catch (error) {
    console.error(`failed to mark direct message as read`, error)
  }
}

export async function getUrlPreview(url) {
  try {
    const response = await api.getUrlPreview(url)
    return response.preview
  } catch (error) {
    if (`${error}`.includes('Invalid URL')) return { url, invalidUrl: true }
    throw error
  }
}

export async function editMessage(chatChannelUid, messageUid, { message }) {
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  const chatMessages = this.getState()[chatMessagesKey] || []

  const messageIndex = chatMessages.findIndex(message => message.uid === messageUid)
  if (messageIndex === -1) throw new Error(`Chat message with UID ${messageUid} not found.`)

  const updatedMessage = { ...chatMessages[messageIndex], message }
  this.setState({
    [chatMessagesKey]: [
      ...chatMessages.slice(0, messageIndex),
      updatedMessage,
      ...chatMessages.slice(messageIndex + 1),
    ],
  })

  try {
    const response = await api.editChatMessage(chatChannelUid, messageUid, message)
    if (response.message !== updatedMessage.message) {
      this.setState({
        [chatMessagesKey]: [
          ...chatMessages.slice(0, messageIndex),
          response,
          ...chatMessages.slice(messageIndex + 1),
        ],
      })
    }

    return response
  } catch (error) {
    this.setState({
      [chatMessagesKey]: [
        ...chatMessages.slice(0, messageIndex),
        chatMessages[messageIndex],
        ...chatMessages.slice(messageIndex + 1),
      ],
    })

    throw error
  }
}


export async function replyToMessage(chatChannelUid, messageUid, { replyMessage }) {

  const initialState = this.getState()
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  const chatMessages = initialState[chatMessagesKey] || []

  const originalMessageIndex = chatMessages.findIndex(message => message.uid === messageUid)
  if (originalMessageIndex === -1) {
    throw new Error(`Chat message with UID ${messageUid} not found.`)
  }

  const originalMessage = chatMessages[originalMessageIndex]
  const reply = {
    createdAt: Date.now(),
    uid: `reply-${Date.now()}`,
    channelUid: chatChannelUid,
    message: replyMessage,
    originalMessage,
  }

  const updatedChatMessages = [...chatMessages, reply]
  this.setState({ [chatMessagesKey]: updatedChatMessages })

  try {
    const replyContent = {
      originalMessage: originalMessage.message,
      reply: replyMessage
    }
    const replyResponse = await api.replyToChatMessage(chatChannelUid, messageUid, replyContent)

    if (!replyResponse || !replyResponse.uid) {
      throw new Error("API did not return a valid message object")
    }

    const index = updatedChatMessages.findIndex(message => message.uid === reply.uid)
    if (index !== -1) {
      updatedChatMessages.splice(index, 1, replyResponse)
      this.setState({ [chatMessagesKey]: updatedChatMessages })
    }

    return replyResponse

  } catch (error) {

    const index = updatedChatMessages.findIndex(message => message.uid === reply.uid)
    if (index !== -1) {
      updatedChatMessages.splice(index, 1)
      this.setState({ [chatMessagesKey]: updatedChatMessages })
    }
    throw error
  }
}


export async function deleteMessage(
  chatChannelUid,
  messageUid,
  { deletionReason = "", AdminComment = "" }
) {
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  try {
    const deletedMessage = await api.deleteChatMessage(chatChannelUid, messageUid, deletionReason, AdminComment)

    this.setState({ [chatMessagesKey]: deletedMessage })
  } catch (error) {
    console.error(`Failed to delete chat message`, error)
    throw error
  }
}

export async function undoDeleteMessage(chatChannelUid, messageUid, action) {
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  let chatMessages = this.getState()[chatMessagesKey] || []

  try {
    const undeletedMessage = await api.undoDeleteChatMessage(chatChannelUid, messageUid, action)
    if (action === 'approve') {
      const messageIndex = chatMessages.findIndex(m => m.uid === messageUid)
      if (messageIndex !== -1) {
        chatMessages[messageIndex] = undeletedMessage
      } else {
        chatMessages = [...chatMessages, undeletedMessage]
      }
      this.setState({ [chatMessagesKey]: undeletedMessage })
    }

  } catch (error) {
    console.error(`Failed to process undelete request`, error)
    throw error
  }
}
