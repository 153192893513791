
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import { h, Fragment } from 'preact'
import { useState, useRef, useEffect } from 'preact/hooks'
import './index.sass'
import Header from 'components/Header'
import Form from 'components/Form'
import Button from 'components/Button'
import NotificationsModal from 'components/NotificationsModal'
import { useRequestOrganizationDeletion } from 'lib/organizationAdminHooks'
import { createPageAlert } from 'resources/pageAlerts'

export default function DeleteHubPage({organization}) {
  const organizationApikey = organization.apikey
  const [showModal, setShowModal] = useState(true)
  const [passkeyModal, setPasskeyModal] = useState(false)
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  const mainModalRef = useRef(null)
  const passkeyModalRef = useRef(null)

  const { requestDeletion } = useRequestOrganizationDeletion(organizationApikey, 'DeleteHubPage')


  function displayModal() {
    setShowModal(!showModal)
    window.history.back()
  }

  function passModal() {
    setPasskeyModal(!passkeyModal)
    setShowModal(!showModal)
  }

  useEffect(() => {
    function handleOutsideClick(event) {
      console.log("Document was clicked")

      if (showModal && mainModalRef.current) {
        if (!mainModalRef.current.contains(event.target)) {
          console.log("Outside Main Modal was clicked")
          setShowModal(false)
        }
      }

      if (passkeyModal && passkeyModalRef.current) {
        if (!passkeyModalRef.current.contains(event.target)) {
          console.log("Outside Passkey Modal was clicked")
          setPasskeyModal(false)
        }
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [showModal, passkeyModal])

  // Handle form submission
  function handleSubmit(e) {
    e.preventDefault()
    const current = window.sessionStorage.getItem('fileKey')

    if (current === password) {
      setError('')
      requestDeletion(organizationApikey)
        .then(() => {
          setPasskeyModal(false)
          createPageAlert({
            message: "Deletion request sent to support successfully! We will get back to you as soon as possible",
            type: "success"
          })
        })
        .catch(() => {
          createPageAlert({
            message: "Error sending the deletion request.",
            type: "error"
          })
        })
    } else {
      setError('incorrect password!!!')
    }
  }

  return (
    <Fragment>
      {showModal && (
        <div ref={mainModalRef}>
          <Modal style={{ width: '60%' }}>
            <Header size="lg">Permanently delete hub?</Header>
            <p>when you delete your hub, you won't be able to retrieve the content or information your hub shared,
              all your messages will be deleted.</p>
            <hr />
            <div>
              <aside className="Modal_info">
                <span>
                  <Icon type="download" />
                  <h3>download your information</h3>
                </span>
                <p>you have to download photos and other information stored in your hub.
                  You can download this content before it's permanently deleted along with the hub</p>
                <button className="Modal_button cancel">download info</button>
              </aside>
              <hr />
              <aside className="Modal_info">
                <span>
                  <Icon type="folder" />
                  <h3>Transfer a copy of your information</h3>
                </span>
                <p>before deleting the hub, you can transfer a copy of your information to another service.
                  Information you can transfer includes post, photos, and videos</p>
                <button className="Modal_button">Transfer info</button>
              </aside>
            </div>
            <div className="small_btn">
              <button className="small_cancel nextBtn" onClick={displayModal}>cancel</button>
              <button className="small_continue nextBtn" onClick={passModal}>continue</button>
            </div>
          </Modal>
        </div>
      )}

      {passkeyModal && (
        <div ref={passkeyModalRef}>
          <Modal>
            <p className="passkey_center">Confirm password</p>
            <Form onSubmit={handleSubmit}>
              <Form.Item>
                <Form.Label>Password</Form.Label>
                <input
                  type="password"
                  autoComplete="current-password"
                  className="TextInput" value={password}
                  onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>
              <p className="error">{error}</p>
              <Form.ButtonRow>
                <Button type="primary" submit>Submit</Button>
                <NotificationsModal />
              </Form.ButtonRow>
            </Form>
          </Modal>
        </div>
      )}
    </Fragment>
  )
}
