// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("components/AvatarInput/default.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EndUserAvatar{user-select:none;display:inline-block;border-radius:100%;max-width:initial;overflow:hidden;flex-shrink:0}.EndUserAvatar.ImageWithLoader-errored,.EndUserAvatar.ImageWithLoader-empty{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}.EndUserAvatar-tn{height:20px;width:20px}.EndUserAvatar-sm{height:33px;width:33px}.EndUserAvatar-md{height:150px/2;width:150px/2}.EndUserAvatar-lg{height:150px;width:150px}.EndUserAvatar-bordered{box-shadow:0px 0px 2px 1px rgba(0,0,0,.2)}", "",{"version":3,"sources":["webpack://./components/EndUserAvatar/index.sass"],"names":[],"mappings":"AAEA,eACE,gBAAA,CACA,oBAAA,CACA,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,aAAA,CACA,4EAEE,wDAAA,CACA,uBAAA,CAEF,kBACE,WAAA,CACA,UAAA,CACF,kBACE,WAAA,CACA,UAAA,CACF,kBACE,cAAA,CACA,aAAA,CACF,kBACE,YAAA,CACA,WAAA,CAEF,wBACE,yCAAA","sourcesContent":["@import \"style/mixins.sass\"\n\n.EndUserAvatar\n  user-select: none\n  display: inline-block\n  border-radius: 100%\n  max-width: initial\n  overflow: hidden\n  flex-shrink: 0\n  &.ImageWithLoader-errored,\n  &.ImageWithLoader-empty\n    background-image: url(\"components/AvatarInput/default.png\")\n    background-size: contain\n\n  &-tn\n    height: 20px\n    width: 20px\n  &-sm\n    height: 33px\n    width: 33px\n  &-md\n    height: 150px / 2\n    width: 150px / 2\n  &-lg\n    height: 150px\n    width: 150px\n\n  &-bordered\n    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.20)\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
