import { h } from 'preact'
import { useRef, useErrorBoundary, useMemo } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import classNames from 'lib/classNames'
import sortBy from 'lib/sortBy'
import { useAppState } from 'lib/appState'
import { useNotifications } from 'lib/notificationsHooks'
import { useOrganization, usePublicProfile, useMyPublicProfile } from 'lib/membershipAppStateHooks'
import useOnScrolledIntoView from 'lib/useOnScrolledIntoViewHook'
import { publicProfileToDisplayName, publicProfileToPathname } from 'lib/publicProfiles'

import Link from 'components/Link'
import TimeAgo from 'components/TimeAgo'
import _OrganizationIcon from 'components/OrganizationIcon'
import Icon from 'components/Icon'
import InfiniteScrollDown from 'components/InfiniteScrollDown'
import ErrorMessage from 'components/ErrorMessage'
import './index.sass'
import { useChatSynopsis, useChatMessages } from 'lib/chatHooks'
import { Fragment} from 'preact'


export default function NotificationsList({ onNotificationClick }) {
  const {
    notifications,
    loadingNotifications,
    loadingNotificationsError,
    lastPageOfNotifications,
    loadNotifications,
  } = useNotifications('NotificationsList')
  const { myPublicProfile } = useMyPublicProfile('NotificationsList')

  return <InfiniteScrollDown
    className="NotificationsList"
    name="notifications"
    loadMore={loadNotifications}
    loading={loadingNotifications}
    loadingError={loadingNotificationsError}
    fullyLoaded={lastPageOfNotifications}
    loaderSize="md"
    emptyMessage={`You have no notifications yet`}
    fullyLoadedMessage={`You have no more notifications`}
  >
    {sortBy(notifications, [n => -(new Date(n.createdAt)).getTime()])
      .map(notification =>
        <NotificationWithErrorCatching {...{
          key: notification.uid,
          notification,
          onClick: onNotificationClick,
          myPublicProfile,
        }}/>
      )
    }
  </InfiniteScrollDown>
}

const TYPES = {
  nodeMembershipInviteAccepted: ({ organizationApikey, inviteePublicProfileDid }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/members`,
    message: <span>
      <PublicProfileName did={inviteePublicProfileDid}/>
      &nbsp;has accepted your invite to join&nbsp;
      <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  nodeMembershipRequestResolved: ({ organizationApikey, accept}) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/> has {accept ? 'accepted' : 'declined'} your request to join
    </span>,
  }),
  nodeMembershipRoleChange: ({ organizationApikey, granted, role }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/members`,
    message: <span>
      You are {granted ? 'now a' : 'no longer a'}&nbsp;
      {role} of <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  requestedNodeMembership: ({ organizationApikey, requesterPublicProfileDid }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/members`,
    message: <span>
      <PublicProfileName did={requesterPublicProfileDid}/>
      &nbsp;requested to join&nbsp;
      <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  sisaPermissionChanged: ({ organizationApikey, sisaEventId }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/sisa-events/${sisaEventId}`,
    message: <span>
      You updated permissions under your SISA with&nbsp;
      <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  sisaPersonalDataChanged: ({ organizationApikey, sisaEventId }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/sisa-events/${sisaEventId}`,
    message: <span>
      You updated your personal data under your SISA with&nbsp;
      <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  inviteToJoinOrganization: ({ organizationApikey }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/join`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/>
      &nbsp;has invited you to join their {PNFO.singular}
    </span>,
  }),
  inviteToJoinOrganizationNetwork: ({ organizationApikey, networkOrganizationApikey }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/admin/networks/invites`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/>
      &nbsp;has been invited to be listed on&nbsp;
      <OrganizationName organizationApikey={networkOrganizationApikey}/>'s Network
    </span>,
  }),
  inviteToJoinOrganizationNetworkResolved: ({ organizationApikey, memberOrganizationApikey, accepted }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: (accepted
      ? `/${organizationApikey}/network/${memberOrganizationApikey}`
      : `/${organizationApikey}/admin/network/invite?q=` +
        encodeURIComponent(memberOrganizationApikey)
    ),
    message: <span>
      <OrganizationName organizationApikey={memberOrganizationApikey}/>
      &nbsp;has {accepted ? 'accepted' : 'declined'} your invitation to be listed on&nbsp;
      <OrganizationName {...{organizationApikey}}/>'s Network
    </span>,
  }),
  removedFromOrganizationNetwork: ({ organizationApikey, networkOrganizationApikey }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${networkOrganizationApikey}/network`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/>
      &nbsp;has been unlisted from&nbsp;
      <OrganizationName organizationApikey={networkOrganizationApikey}/>'s Network
    </span>,
  }),
  promptToCompletePublicProfile: ({ myPublicProfile: publicProfile }) => ({
    icon: <Icon type="edit"/>,
    href: `${publicProfileToPathname({ ...publicProfile })}/edit`,
    message: <span>
      Complete your profile. Upload an avatar and choose a display name.
    </span>,
  }),
  promptToManageNotificationSettings: () => ({
    icon: <Icon type="notifications"/>,
    href: '/settings',
    message: <span>
      Visit settings to turn on desktop notifications + manage email & sms/mobile notifications.
    </span>,
  }),
  organizationAnnouncement: ({ organizationApikey }) => ({
    icon: <Icon type="megaphone"/>,
    href: `/${organizationApikey}/forum`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/> has a new announcement
    </span>,
  }),

  chatMessageDeletedByAdminandCurator: ({channelUid, deletionReason, messageUid, AdminComment }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { chatMessages } = useChatMessages(channelUid)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { myPublicProfile } = useMyPublicProfile('NotificationsList')

    const message = chatMessages?.find((m) => m.uid === messageUid)
    const creatorUserDid = message?.creatorUserDid
    const deletedByRole = message?.deletedByRole

    const myPublicProfileDid = myPublicProfile.did

    let notificationMessage

    if(myPublicProfileDid === creatorUserDid) {
      notificationMessage = (
        <span>
          Your message in <ChannelName channelUid={channelUid}/> has been deleted by {deletedByRole} .
          Reason: {deletionReason}
          {AdminComment && <><br/>Comment: {AdminComment}</>}
          <br>----------</br>
          <br/><PublicProfileName did={creatorUserDid}/>
          <br/>Message: {message ? message.message : 'Message not found'}
        </span>
      )
    } else {
      notificationMessage = (
        <span>
          You deleted a message for user  <br/><PublicProfileName did={creatorUserDid}/>
          &nbsp;in <ChannelName channelUid={channelUid}/>
          &nbsp;as the {deletedByRole}.
          Reason: {deletionReason}
          {AdminComment && <><br/>Comment: {AdminComment}</>}
          <br>----------</br>
          <br/>Message: {message ? message.message : 'Message not found'}
        </span>
      )
    }

    return {
      icon: <Icon type="trash" />,
      // eslint-disable-next-line new-cap
      href: `/${ChannelName({ channelUid })}/chat/${channelUid}?message-${messageUid}`,
      message: notificationMessage,
      onClick: () => {
        navigateToMessage(messageUid)
      }
    }
  },

  chatMessageUndeleteRequest: ({ channelUid, messageUid}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { chatMessages } = useChatMessages(channelUid)

    const message = chatMessages?.find((m) => m.uid === messageUid)

    if (!message) {
      console.warn("Message not found in chatMessages for uid:", messageUid)
    }

    const creatorUserDid = message?.creatorUserDid

    return {
      icon: <Icon type="edit" />,
      // eslint-disable-next-line new-cap
      href: `/${ChannelName({ channelUid })}/chat/${channelUid}?message-${messageUid}`,
      message: (
        <span>
        User <PublicProfileName did={creatorUserDid}/> has requested to undo the deletion of
         their message in <ChannelName channelUid={channelUid} /> channel.
          <br />Modified Message: {message ? message.editedMessage : 'Message not found'}
          <br />Previous Message: {message ? message.message : 'Message not found'}
        </span>
      ),
      onClick: () => {
        navigateToMessage(messageUid)
      }
    }
  },

  chatMessageReinstated: ({ channelUid, messageUid }) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { chatMessages } = useChatMessages(channelUid)
    const message = chatMessages?.find((m) => m.uid === messageUid)
    const deletedByRole = message?.deletedByRole


    return {
      icon: <Icon type="undo" />,

      // eslint-disable-next-line new-cap
      href: `/${ChannelName({ channelUid })}/chat/${channelUid}?message-${messageUid}`,
      message: (
        <span>
          Your message in <ChannelName channelUid={channelUid} /> has been reinstated by the {deletedByRole}.
          <br />Message: {message ? message.editedMessage : 'Message not found'}
        </span>
      ),
      onClick: () => {
        navigateToMessage(messageUid)
      }
    }
  },
  chatMessageUndeleteDenied: ({ channelUid, messageUid }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { chatMessages } = useChatMessages(channelUid)
    const message = chatMessages?.find((m) => m.uid === messageUid)
    const creatorUserDid = message?.creatorUserDid

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { myPublicProfile } = useMyPublicProfile('NotificationsList')

    const myPublicProfileDid = myPublicProfile.did

    let notificationMessage

    if (myPublicProfileDid === creatorUserDid) {
      notificationMessage = (
        <span>
          Your request to undelete a message in <ChannelName channelUid={channelUid} /> was denied.
          <br />Message: {message ? message.editedMessage : 'Message not found'}
        </span>
      )
    } else {
      notificationMessage = (
        <span>
          You denied a request to undelete a message in <ChannelName channelUid={channelUid} />.
          <br />User: <PublicProfileName did={creatorUserDid} />
          <br />Message: {message ? message.editedMessage : 'Message not found'}
        </span>
      )
    }

    return {
      icon: <Icon type="cancel" />,
      // eslint-disable-next-line new-cap
      href: `/${ChannelName({ channelUid })}/chat/${channelUid}?message-${messageUid}`,

      message: notificationMessage,
      onClick: () => navigateToMessage(messageUid)
    }
  },

  postDeletedByAdminToReposter: ({ organizationApikey, fromOrganizationApikey, feedPostUid, reason, comment }) => ({
    icon: <Icon type="trash"/>,
    href: `/${organizationApikey}/forum/${feedPostUid}`,
    message: <span>
      The post you re-posted to&nbsp;
      <OrganizationName {...{ organizationApikey }}/> &nbsp;
      was deleted by an admin of <OrganizationName {...{
        organizationApikey: fromOrganizationApikey,
      }} />&nbsp;
      {reason && <span> for {reason}</span>} <br/>
      {comment && <span>Admin comment: {comment}</span>}
    </span>,
  }),
  postDeletedByAdmin: ({ organizationApikey, feedPostUid, reason, comment }) => ({
    icon: <Icon type="trash"/>,
    href: `/${organizationApikey}/forum/${feedPostUid}`,
    message: <span>
      Your post was deleted by an admin of&nbsp;
      <OrganizationName {...{organizationApikey}}/>
      {reason && <span> for {reason}</span>} <br/>
      {comment && <span>Admin comment: {comment}</span>}
    </span>,
  }),
}

function NotificationWithErrorCatching({ notification, myPublicProfile, ...props }){
  const [error, onDismiss] = useErrorBoundary()
  if (error) {
    console.error(error)
    return <ErrorMessage {...{error, onDismiss}} />
  }
  if (notification.type in TYPES){
    const {icon, href, message, onClick} = TYPES[notification.type]({myPublicProfile, ...notification})
    return <Notification {...{...props, notification, icon, href, message, onClick}}/>
  }
  return <Notification {...{
    notification,
    message: `ERROR: unknown notification type=${notification.type}`,
  }}/>
}

function Notification({
  notification: { type, uid, seenAt, createdAt },
  icon, href, message, onClick,
}) {
  const unread = !seenAt
  const ref = useRef()
  const { takeAction } = useAppState(undefined, 'NotificationsList')

  useOnScrolledIntoView(
    ref,
    () => { if (unread) takeAction('notifications.markAsRead', uid) },
    [uid, unread]
  )

  return <Link {...{
    className: classNames('NotificationsList-Notification', { unread, [type]: 1 }),
    ref, href, onClick,
  }}>
    <div>{icon}</div>
    <div>
      <div>{message}</div>
      <TimeAgo time={createdAt} />
    </div>
  </Link>
}

function OrganizationName({ organizationApikey }){
  const { organization } = useOrganization(organizationApikey, 'NotificationsList')
  return <span>{(organization && organization.name) || organizationApikey}</span>
}

function PublicProfileName({ did }){
  const { publicProfile = {} } = usePublicProfile(did, 'NotificationsList')
  return <span>{publicProfileToDisplayName(publicProfile)}</span>
}

function OrganizationIcon({ organizationApikey }){
  const { organization } = useOrganization(organizationApikey, 'NotificationsList')
  return <_OrganizationIcon {...{organization}}/>
}

export function ChannelName({ channelUid }) {
  const { chatSynopsis } = useChatSynopsis("NotificationsList")
  const channelName = useMemo(() => {
    const uid =
      typeof channelUid === "object" ? channelUid.channelUid : channelUid
    const channel = chatSynopsis.find((c) => c.uid === uid)
    return channel ? channel.organizationApikey : "Unknown Channel"
  }, [chatSynopsis, channelUid])

  return channelName
}

export function navigateToMessage(messageUid) {
  const element = document.getElementById(`message-${messageUid}`)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    element.classList.add('highlighted-message')
    setTimeout(() => {
      element.classList.remove('highlighted-message')
    }, 30000)
  } else {
    console.log("Element not found for id:", `message-${messageUid}`)
  }
}
