import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Link from 'components/Link'
import Header from 'components/Header'
import TimeAgo from 'components/TimeAgo'
import './index.sass'

const MEDIA_ATTACHMENT_PREFIX = 'jlinc:dm:attachment:'

export default function ChatChannelListMember({
  className,
  avatar,
  preview,
  name,
  date,
  badge,
  ...props
}){
  return <Link
    {...props}
    className={classNames('ChatChannelListMember', { className })}
  >
    {avatar}
    <div className="ChatChannelListMember-details">
      <Header size="md">{name}</Header>
      {preview &&
        <div className="ChatChannelListMember-preview">
          <span>
            {preview.startsWith(MEDIA_ATTACHMENT_PREFIX)
              ? previewStringForMedia(preview).substring(0, 37).concat('...')
              : stripHTML(preview).substring(0, 37).concat('...')
            }
          </span>
          {date &&
            <Fragment>
              <span> • </span>
              <TimeAgo time={date} tiny />
            </Fragment>
          }
        </div>
      }
    </div>
    {badge}
  </Link>
}

ChatChannelListMember.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.node.isRequired,
  preview: PropTypes.string,
  name: PropTypes.string.isRequired,
  date: PropTypes.string,
  badge: PropTypes.node,
}

const previewStringForMedia = preview =>
  preview.match(/"type":"([^"]+)"/)
    ? `sent ${RegExp.$1}`
    : 'sent media attachment'

function stripHTML(html){
  const div = global.document.createElement('div')
  div.innerHTML = html
  return div.textContent.trim()
}
